<template>
  <div class="grotta-home-book">
    <div class="grotta-home-book__header">
      <h1 class="grotta-home-book__header_title" align="center">
        The Grotta Home by Richard Meier
      </h1>
      <span class="grotta-home-book__header_subtitle">A Marriage of Architecture and Craft</span>
    </div>

    <div class="grotta-home-book__body">
      <span class="grotta-home-book__body_co-authors" align="center">
        With contributions by Glenn Adamson, Matthew Drutt, Sheila Hicks, Joseph Giovannini, Louis
        Grotta, Jack Lenor Larsen, John McQueen, Richard Meier, Wendy Ramshaw and David Watkins.
      </span>
      <img
        class="grotta-home-book__body_main-image"
        v-webp="require('@/assets/images/books/home-book-cover.jpg')"
        src="@/assets/images/books/home-book-cover.jpg"
        alt="The Grotta Home by Richard Meier"
        decoding="async"
        loading="lazy"
      />

      <section class="details details__container">
        <div class="details__properties">
          <h2 class="details__properties_title">Book Details</h2>
          <div class="details__properties_box">
            <div v-for="(item, index) in bookProperties" :key="`property-${index}`" class="item">
              <span class="item_title">{{ item.title }}:</span>
              <span class="item_value">{{ item.value }}</span>
            </div>
          </div>
        </div>
        <span class="details__description">
          A ‘vessel for living’ Glenn Adamson called the remarkable residence Richard Meier designed
          to house Sandra and Louis Grotta’s collection of contemporary studio jewellery and
          significant works in wood, ceramic and fiber. The building was conceived around the
          collection, framing the objects within the open architecture, which comprises an equal
          blend of glass and concrete. Nature, visible from many vantage points, plays an essential
          supporting role. The Grotta Home by Richard Meier: A Marriage of Architecture and Craft is
          rich in photographs of the collection and provides impressive insights into this
          exceptionally personal project by Richard Meier. The accompanying essays afford the reader
          a greater sense of how the Grottas have not simply acquired art but have immersed
          themselves in it.
        </span>
        <div class="details__buttons-box">
          <BaseButton
            class="button--oval button--white"
            text="Buy Now"
            @click.native="followLink"
          />
          <BaseButton
            class="button--outline-white button--oval"
            text="Preview Online"
            @click.native="showPreview = true"
          />
          <ShareBar
            class="button--share"
            title="The Grotta Home by Richard Meier"
            description='"The book, “The Grotta Home" is an intimate portrait of the union of two individuals,
          fused into a singular point of view and seen through the lens of the artifacts that have
          defined their passionate journey.'
            :image="bookImage"
          />
        </div>
      </section>

      <section class="creators creators__container">
        <h2 class="creators__title">Related Creators</h2>
        <div class="creators__group">
          <span
            v-for="(creator, key) in creators"
            :key="`creator-${key}`"
            class="creators__group_item"
            :class="{ clickable: creator.link }"
            @click="goToArtistPage(creator.link)"
            >{{ creator.lastName }}, {{ creator.firstName }}</span
          >
        </div>
      </section>

      <!-- <section class="reviews reviews__container">
        <h2 class="reviews__title">Reviews</h2>
        <div v-for="(review, index) in reviews" :key="`review-${index}`" class="reviews__item">
          <span class="reviews__item_text">{{ review.text }}</span>
          <span class="reviews__item_author">by {{ review.author }}</span>
        </div>
        <BaseButton
          class="reviews__button button--outline-white button--oval"
          text="Load More"
        />
      </section> -->
    </div>

    <div class="grotta-home-book__pop-up" :class="{ visible: showPreview }">
      <div class="grotta-home-book__pop-up_header">
        <BaseButton
          class="button--oval button--outline-white"
          text="Exit preview"
          @click.native="showPreview = false"
        />
      </div>

      <iframe
        ref="iframePdf"
        class="pdf-viewer"
        scrolling="no"
        src="https://arnoldsche.com/us/product/the-grotta-home-by-richard-meier/#dflip-df_36920/1/"
      ></iframe>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex";
import BaseButton from "@/components/buttons/BaseButton.vue";
import ShareBar from "@/components/Share.vue";

export default {
  components: { BaseButton, ShareBar },

  data() {
    return {
      showPreview: false,
      currentPage: 1,
      pageCount: 0,
      bookImage: `${process.env.VUE_APP_FRONTEND_URL}/img/books/home-book-cover.webp`,
      bookProperties: [
        { title: "Pages", value: "336 pp" },
        { title: "Size", value: "28 x 30cm" },
        { title: "Format", value: "Hardcover" },
        { title: "Ilustrations", value: "approx. 300ills" },
        { title: "Language", value: "English" },
        { title: "ISBN", value: "978-3-89790-568-9" },
      ],
      creators: [
        { name: "Alvar Aalto", firstName: "Alvar", lastName: "Aalto", link: "" },
        { name: "William Accorsi", firstName: "William", lastName: "Accorsi", link: "" },
        { name: "Allen Adler", firstName: "Allen", lastName: "Adler", link: "" },
        {
          name: "Edgar & Joyce Anderson",
          firstName: "Edgar & Joyce",
          lastName: "Anderson",
          link: "",
        },
        { name: "Robert Arneson", firstName: "Robert", lastName: "Arneson", link: "" },
        { name: "Gae Aulenti", firstName: "Gae", lastName: "Aulenti", link: "" },
        { name: "Rudy Autio", firstName: "Rudy", lastName: "Autio", link: "" },
        { name: "Giampaolo Babetto", firstName: "Giampaolo", lastName: "Babetto", link: "" },
        { name: "Hans Bacher", firstName: "Hans", lastName: "Bacher", link: "" },
        { name: "Gijs Bakker", firstName: "Gijs", lastName: "Bakker", link: "" },
        { name: "Richard Barth", firstName: "Richard", lastName: "Barth", link: "" },
        { name: "Michael Becker", firstName: "Michael", lastName: "Becker", link: "" },
        { name: "Bengt Berglund", firstName: "Bengt", lastName: "Berglund", link: "" },
        { name: "Harry Bertoia", firstName: "Harry", lastName: "Bertoia", link: "" },
        { name: "Birgit Birkkjær", firstName: "Birgit", lastName: "Birkkjær", link: "" },
        { name: "Kay Bojesen", firstName: "Kay", lastName: "Bojesen", link: "" },
        { name: "Mario Botta", firstName: "Mario", lastName: "Botta", link: "" },
        { name: "Sara Brennan", firstName: "Sara", lastName: "Brennan", link: "" },
        { name: "Brothers Campana", firstName: "Brothers", lastName: "Campana", link: "" },
        { name: "Wendell Castle", firstName: "Wendell", lastName: "Castle", link: "" },
        { name: "Chunghi Choo", firstName: "Chunghi", lastName: "Choo", link: "" },
        { name: "Andreas Christensen", firstName: "Andreas", lastName: "Christensen", link: "" },
        { name: "William Clark", firstName: "William", lastName: "Clark", link: "" },
        { name: "Carmen Collell", firstName: "Carmen", lastName: "Collell", link: "" },
        { name: "Hans Coper", firstName: "Hans", lastName: "Coper", link: "" },
        { name: "Charles Crowley", firstName: "Charles", lastName: "Crowley", link: "" },
        { name: "William Daley", firstName: "William", lastName: "Daley", link: "" },
        { name: "Richard DeVore", firstName: "Richard", lastName: "DeVore", link: "" },
        { name: "Dominic Di Mare", firstName: "Dominic", lastName: "Di Mare", link: "" },
        { name: "Georg Dobler", firstName: "Georg", lastName: "Dobler", link: "" },
        { name: "Ruth Duckworth", firstName: "Ruth", lastName: "Duckworth", link: "" },
        {
          name: "Charles & Ray Eames",
          firstName: "Charles & Ray",
          lastName: "Eames",
          link: "",
        },
        { name: "Jack Earle", firstName: "Jack", lastName: "Earle", link: "" },
        { name: "Robert Ebendorf", firstName: "Robert", lastName: "Ebendorf", link: "" },
        { name: "Eva Eisler", firstName: "Eva", lastName: "Eisler", link: "" },
        { name: "Andreas Fabian", firstName: "Andreas", lastName: "Fabian", link: "" },
        { name: "Zhou Ding Fang", firstName: "Zhou Ding", lastName: "Fang", link: "" },
        { name: "Patty Fawn", firstName: "Patty", lastName: "Fawn", link: "" },
        { name: "Kenneth Ferguson", firstName: "Kenneth", lastName: "Ferguson", link: "" },
        { name: "Arline M. Fisch", firstName: "Arline M.", lastName: "Fisch", link: "" },
        { name: "Susie Freeman", firstName: "Susie", lastName: "Freeman", link: "" },
        { name: "Viola Frey", firstName: "Viola", lastName: "Frey", link: "" },
        { name: "Bent Gabrielsen", firstName: "Bent", lastName: "Gabrielsen", link: "" },
        { name: "Emidio Galassi", firstName: "Emidio", lastName: "Galassi", link: "" },
        { name: "Elizabeth Garrison", firstName: "Elizabeth", lastName: "Garrison", link: "" },
        { name: "Frank Gehry", firstName: "Frank", lastName: "Gehry", link: "" },
        { name: "David Gilhooly", firstName: "David", lastName: "Gilhooly", link: "" },
        { name: "Mary Giles", firstName: "Mary", lastName: "Giles", link: "" },
        { name: "Jonathan Glick", firstName: "Jonathan", lastName: "Glick", link: "" },
        { name: "Larry Gotsch", firstName: "Larry", lastName: "Gotsch", link: "" },
        { name: "Lisa Gralnick", firstName: "Lisa", lastName: "Gralnick", link: "" },
        { name: "Michael Graves", firstName: "Michael", lastName: "Graves", link: "" },
        { name: "Walter Gropius", firstName: "Walter", lastName: "Gropius", link: "" },
        { name: "Françoise Grossen", firstName: "Françoise", lastName: "Grossen", link: "" },
        { name: "Maija Grotell", firstName: "Maija", lastName: "Grotell", link: "" },
        { name: "Laurie Hall", firstName: "Laurie", lastName: "Hall", link: "" },
        { name: "Ineke Hans", firstName: "Ineke", lastName: "Hans", link: "" },
        { name: "Kaija Sanelma Harris", firstName: "Kaija Sanelma", lastName: "Harris", link: "" },
        { name: "Helena Hernmarck", firstName: "Helena", lastName: "Hernmarck", link: "" },
        { name: "Sheila Hicks", firstName: "Sheila", lastName: "Hicks", link: "" },
        { name: "Wayne Higby", firstName: "Wayne", lastName: "Higby", link: "" },
        { name: "Therese Hilbert", firstName: "Therese", lastName: "Hilbert", link: "" },
        { name: "Marion Hildebrandt", firstName: "Marion", lastName: "Hildebrandt", link: "" },
        { name: "Joseph Hoffman", firstName: "Joseph", lastName: "Hoffman", link: "" },
        { name: "Ann Hollandale", firstName: "Ann", lastName: "Hollandale", link: "" },
        { name: "Nicholas Homoky", firstName: "Nicholas", lastName: "Homoky", link: "" },
        { name: "James Horn", firstName: "James", lastName: "Horn", link: "" },
        { name: "Thomas Hucker", firstName: "Thomas", lastName: "Hucker", link: "" },
        { name: "Kiyomi Iwata", firstName: "Kiyomi", lastName: "Iwata", link: "" },
        { name: "Ritzi Jacobi", firstName: "Ritzi", lastName: "Jacobi", link: "" },
        { name: "Ferne Jacobs", firstName: "Ferne", lastName: "Jacobs", link: "" },
        { name: "Arne Jacobsen", firstName: "Arne", lastName: "Jacobsen", link: "" },
        { name: "Stéphanie Jacques", firstName: "Stéphanie", lastName: "Jacques", link: "" },
        { name: "Georg Jensen", firstName: "Georg", lastName: "Jensen", link: "" },
        { name: "Karen Karnes", firstName: "Karen", lastName: "Karnes", link: "" },
        { name: "Tamiko Kawata", firstName: "Tamiko", lastName: "Kawata", link: "" },
        { name: "Marilyn Keating", firstName: "Marilyn", lastName: "Keating", link: "" },
        { name: "Ray Key", firstName: "Ray", lastName: "Key", link: "" },
        { name: "William Keyser", firstName: "William", lastName: "Keyser", link: "" },
        { name: "Margo Kempe", firstName: "Margo", lastName: "Kempe", link: "" },
        { name: "Naomi Kobayashi", firstName: "Naomi", lastName: "Kobayashi", link: "" },
        { name: "Henning Koppel", firstName: "Henning", lastName: "Koppel", link: "" },
        { name: "Markku Kosonen", firstName: "Markku", lastName: "Kosonen", link: "" },
        { name: "Howard Kottler", firstName: "Howard", lastName: "Kottler", link: "" },
        { name: "Luba Krejci", firstName: "Luba", lastName: "Krejci", link: "" },
        { name: "Bo Kristiansen", firstName: "Bo", lastName: "Kristiansen", link: "" },
        { name: "Lilla Kulka", firstName: "Lilla", lastName: "Kulka", link: "" },
        { name: "Kyoko Kumai", firstName: "Kyoko", lastName: "Kumai", link: "" },
        { name: "Otto Künzli", firstName: "Otto", lastName: "Künzli", link: "" },
        { name: "Gyöngy Laky", firstName: "Gyöngy", lastName: "Laky", link: "" },
        { name: "Thomas Latané", firstName: "Thomas", lastName: "Latané", link: "" },
        { name: "Jack Lenor Larsen", firstName: "Jack Lenor", lastName: "Larsen", link: "" },
        { name: "James Lawton", firstName: "Thomas", lastName: "Lawton", link: "" },
        { name: "Chief Don Lelooska", firstName: "Chief Don", lastName: "Lelooska", link: "" },
        { name: "Alexander Lichtveld", firstName: "Alexander", lastName: "Lichtveld", link: "" },
        { name: "David Ling", firstName: "David", lastName: "Ling", link: "" },
        { name: "Marvin Lipofsky", firstName: "Marvin", lastName: "Lipofsky", link: "" },
        { name: "Charles Loloma", firstName: "Charles", lastName: "Loloma", link: "" },
        { name: "Kari Lønning", firstName: "Kari", lastName: "Lønning", link: "" },
        { name: "Dona Look", firstName: "Dona", lastName: "Look", link: "" },
        { name: "Castillo Los", firstName: "Castillo", lastName: "Los", link: "" },
        { name: "Warren Mackenzie", firstName: "Warren", lastName: "Mackenzie", link: "" },
        { name: "Dawn MacNutt", firstName: "Dawn", lastName: "MacNutt", link: "" },
        { name: "Masahiro Maeda", firstName: "Masahiro", lastName: "Maeda", link: "" },
        { name: "Fritz Maierhofer", firstName: "Fritz", lastName: "Maierhofer", link: "" },
        { name: "James Makins", firstName: "James", lastName: "Makins", link: "" },
        { name: "Sam Maloof", firstName: "Sam", lastName: "Maloof", link: "" },
        { name: "Bodil Manz", firstName: "Bodil", lastName: "Manz", link: "" },
        { name: "John Mason", firstName: "John", lastName: "Mason", link: "" },
        { name: "Harrison McIntosh", firstName: "Harrison", lastName: "McIntosh", link: "" },
        { name: "John McQueen", firstName: "John", lastName: "McQueen", link: "" },
        { name: "Richard Meier", firstName: "Richard", lastName: "Meier", link: "" },
        { name: "Linda Mendelson", firstName: "Linda", lastName: "Mendelson", link: "" },
        { name: "Mary Merkel-Hess", firstName: "Mary", lastName: "Merkel-Hess", link: "" },
        { name: "Bruce Metcalf", firstName: "Bruce", lastName: "Metcalf", link: "" },
        {
          name: "Ludwig Mies van der Rohe",
          firstName: "Ludwig",
          lastName: "Mies van der Rohe",
          link: "",
        },
        { name: "Norma Minkowitz", firstName: "Norma", lastName: "Minkowitz", link: "" },
        { name: "Judy Mulford", firstName: "Judy", lastName: "Mulford", link: "" },
        { name: "Ragnar Naess", firstName: "Ragnar", lastName: "Naess", link: "" },
        { name: "Shinji Nakaba", firstName: "Shinji", lastName: "Nakaba", link: "" },
        { name: "Mira Nakashima", firstName: "Mira", lastName: "Nakashima", link: "" },
        {
          name: "Otto & Gertrud Natzler",
          firstName: "Otto & Gertrud",
          lastName: "Natzler",
          link: "",
        },
        { name: "Ronna Neuenschwander", firstName: "Ronna", lastName: "Neuenschwander", link: "" },
        {
          name: "Leon & Sharon Niehues",
          firstName: "Leon & Sharon",
          lastName: "Niehues",
          link: "",
        },
        { name: "Richard Notkin", firstName: "Richard", lastName: "Notkin", link: "" },
        { name: "Jeff Oestreich", firstName: "Jeff", lastName: "Oestreich", link: "" },
        { name: "Pavel Opočenskÿ", firstName: "Pavel", lastName: "Opočenskÿ", link: "" },
        { name: "Martin Page", firstName: "Martin", lastName: "Page", link: "" },
        { name: "Martin Papcun", firstName: "Martin", lastName: "Papcun", link: "" },
        { name: "Gustavo Perez", firstName: "Gustavo", lastName: "Perez", link: "" },
        { name: "Bent Petersen", firstName: "Bent", lastName: "Petersen", link: "" },
        { name: "Peter Petrouchka", firstName: "Peter", lastName: "Petrouchka", link: "" },
        { name: "Mark Pharis", firstName: "Mark", lastName: "Pharis", link: "" },
        { name: "David Pye", firstName: "David", lastName: "Pye", link: "" },
        { name: "Jens Quistgaard", firstName: "Jens", lastName: "Quistgaard", link: "" },
        { name: "Wendy Ramshaw", firstName: "Wendy", lastName: "Ramshaw", link: "" },
        { name: "Vernon Reed", firstName: "Vernon", lastName: "Reed", link: "" },
        { name: "Lucie Rie", firstName: "Lucie", lastName: "Rie", link: "" },
        { name: "Mary Roehm", firstName: "Mary", lastName: "Roehm", link: "" },
        { name: "Ed Rossbach", firstName: "Ed", lastName: "Rossbach", link: "" },
        { name: "Gerd Rothmann", firstName: "Gerd", lastName: "Rothmann", link: "" },
        {
          name: "Mariette Rousseau-Vermette",
          firstName: "Mariette",
          lastName: "Rousseau-Vermette",
          link: "",
        },
        { name: "Axel Russmeyer", firstName: "Axel", lastName: "Russmeyer", link: "" },
        { name: "Amy Sabrina", firstName: "Amy", lastName: "Sabrina", link: "" },
        { name: "Debra Sachs", firstName: "Debra", lastName: "Sachs", link: "" },
        { name: "Norm Satorius", firstName: "Norm", lastName: "Satorius", link: "" },
        { name: "Bill Sax", firstName: "Bill", lastName: "Sax", link: "" },
        { name: "Adrian Saxe", firstName: "Adrian", lastName: "Saxe", link: "" },
        { name: "Edwin & Mary Scheier", firstName: "Edwin & Mary", lastName: "Scheier", link: "" },
        { name: "Katja Schlegel", firstName: "Katja", lastName: "Schlegel", link: "" },
        { name: "M Schuller", firstName: "M", lastName: "Schuller", link: "" },
        { name: "Hisako Sekijima", firstName: "Hisako", lastName: "Sekijima", link: "" },
        { name: "Kay Sekimachi", firstName: "Kay", lastName: "Sekimachi", link: "" },
        { name: "Sylvia Seventy", firstName: "Sylvia", lastName: "Seventy", link: "" },
        { name: "Hiroyuki Shindo", firstName: "Hiroyuki", lastName: "Shindo", link: "" },
        { name: "Rolf Simmemark", firstName: "Rolf", lastName: "Simmemark", link: "" },
        { name: "Karyl Sisson", firstName: "Karyl", lastName: "Sisson", link: "" },
        { name: "Kiff Slemmons", firstName: "Kiff", lastName: "Slemmons", link: "" },
        { name: "Christina Smith", firstName: "Christina", lastName: "Smith", link: "" },
        { name: "Jin-Sook So", firstName: "Jin-Sook", lastName: "So", link: "" },
        { name: "Paul Soldner", firstName: "Paul", lastName: "Soldner", link: "" },
        { name: "Ettore Sottsass", firstName: "Ettore", lastName: "Sottsass", link: "" },
        { name: "Nadine Sterk", firstName: "Nadine", lastName: "Sterk", link: "" },
        { name: "Bob Stocksdale", firstName: "Bob", lastName: "Stocksdale", link: "" },
        { name: "Aleksandra Stoyanov", firstName: "Aleksandra", lastName: "Stoyanov", link: "" },
        { name: "Hiroki Takada", firstName: "Hiroki", lastName: "Takada", link: "" },
        { name: "Toshiko Takaezu", firstName: "Toshiko", lastName: "Takaezu", link: "" },
        { name: "Chiyoko Tanaka", firstName: "Chiyoko", lastName: "Tanaka", link: "" },
        { name: "Hideho Tanaka", firstName: "Hideho", lastName: "Tanaka", link: "" },
        { name: "Lenore Tawney", firstName: "Lenore", lastName: "Tawney", link: "" },
        { name: "Robert Turner", firstName: "Robert", lastName: "Turner", link: "" },
        { name: "Bertil Vallien", firstName: "Bertil", lastName: "Vallien", link: "" },
        { name: "Deborah Valoma", firstName: "Deborah", lastName: "Valoma", link: "" },
        {
          name: "Françoise Van den Bosch",
          firstName: "Françoise",
          lastName: "Van den Bosch",
          link: "",
        },
        { name: "Lonny Van Ryswyck", firstName: "Lonny", lastName: "Van Ryswyck", link: "" },
        { name: "Chision Vatio", firstName: "Chision", lastName: "Van Vatio", link: "" },
        { name: "Tone Vigeland", firstName: "Tone", lastName: "Van Vigeland", link: "" },
        {
          name: "Lella & Massimo Vignelli",
          firstName: "Lella & Massimo",
          lastName: "Vignelli",
          link: "",
        },
        {
          name: "Birgitte Von Wysiecki",
          firstName: "Birgitte",
          lastName: "Von Wysiecki",
          link: "",
        },
        { name: "Peter Voulkos", firstName: "Peter", lastName: "Voulkos", link: "" },
        { name: "Denise Wallace", firstName: "Denise", lastName: "Wallace", link: "" },
        { name: "Barbara Walter", firstName: "Barbara", lastName: "Walter", link: "" },
        { name: "David Watkins", firstName: "David", lastName: "Watkins", link: "" },
        { name: "Björn Weckström", firstName: "Björn", lastName: "Weckström", link: "" },
        { name: "Hans Wegner", firstName: "Hans", lastName: "Wegner", link: "" },
        {
          name: "Gerald & Gotlind Weigel",
          firstName: "Gerald & Gotlind",
          lastName: "Weigel",
          link: "",
        },
        { name: "Tom Wesselmann", firstName: "Tom", lastName: "Wesselmann", link: "" },
        { name: "Katherine Westphal", firstName: "Katherine", lastName: "Westphal", link: "" },
        { name: "Hans Weissflog", firstName: "Hans", lastName: "Weissflog", link: "" },
        { name: "Robert Winokur", firstName: "Robert", lastName: "Winokur", link: "" },
        { name: "Tapio Wirkkala", firstName: "Tapio", lastName: "Wirkkala", link: "" },
        { name: "Beatrice Wood", firstName: "Beatrice", lastName: "Wood", link: "" },
        { name: "Russell Wright", firstName: "Russell", lastName: "Wright", link: "" },
        { name: "William Wyman", firstName: "William", lastName: "Wyman", link: "" },
        { name: "Lu Wen Xia", firstName: "Lu Wen", lastName: "Xia", link: "" },
        { name: "Lu Jian Xing", firstName: "Lu Jian", lastName: "Xing", link: "" },
        { name: "Chang Yeonsoon", firstName: "Chang", lastName: "Yeonsoon", link: "" },
        { name: "Masako Yoshida", firstName: "Masako", lastName: "Yoshida", link: "" },
      ],
      // reviews: [
      //   {
      //     text: '"The book, “The Grotta Home" is an intimate portrait of the union of two individuals, fused into a singular point of view and seen through the lens of the artifacts that have defined their passionate journey. ',
      //     author: "Josh Owen, Josh Owen LLC",
      //   },
      //   {
      //     text: 'The house itself is a vessel that is teeming with their narrative. Meier’s building deploys lightness in a way which warms the repository and balances it between the experiences of living with and dreaming through the objects it encapsulates."',
      //     author: "Josh Owen, Josh Owen LLC",
      //   },
      //   {
      //     text: 'Meier’s building deploys lightness in a way which warms the repository and balances it between the experiences of living with and dreaming through the objects it encapsulates."',
      //     author: "Josh Owen, Josh Owen LLC",
      //   },
      // ],
    };
  },
  head: {
    title: function () {
      return {
        inner: "GH - Book: The Grotta Home",
        separator: " ",
        complement: " ",
      };
    },
    meta: function () {
      return [];
    },
  },

  computed: {
    ...mapGetters("publicArtists", {
      artistsList: "getArtistsList",
    }),
  },

  async created() {
    await this.apiGetArtistsList();

    this.creators.forEach(author => {
      this.artistsList.reduce((previousValue, currentValue) => {
        let name = `${currentValue.first_name} ${currentValue.last_name}`;
        if (name === author.name) {
          author.link = `/creators/${currentValue.slug}`;
        }
      }, []);
    });

    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Library",
        to: "/library",
      },
      {
        title: "Books",
        to: "/library/books",
      },
      {
        title: "THE GROTTA HOME BY RICHARD MEIER",
        to: "/library/the-grotta-home-by-richard-meier",
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
  },

  methods: {
    ...mapMutations(["setBreadcrumbs"]),
    ...mapActions("publicArtists", ["apiGetArtistsList"]),

    followLink() {
      window.open(
        "https://store.browngrotta.com/the-grotta-home-by-richard-meier-a-marriage-of-architecture-and-craft/",
        "_blank"
      );
    },

    goToArtistPage(path) {
      this.$router.push({ path: path });
    },

    slidePdf(direction) {
      if (direction === "next" && this.currentPage < this.pageCount) {
        this.currentPage++;
      }
      if (direction === "prev" && this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
}

.grotta-home-book {
  min-height: 100vh;
  min-width: 100vw;
  background-color: $lightBlack;
  padding: 26.2rem 20.83rem 22.25rem;
  @media only screen and (max-width: $md) {
    padding: 26.2rem 10rem 22.25rem;
  }
  @media only screen and (max-width: $xs) {
    padding: 28.3rem 2.8rem 16rem;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    &_title {
      font-size: 62px;
      line-height: 1.1;
      font-family: $fontBodoni;
      @media only screen and (max-width: $xs) {
        font-size: 39px;
        line-height: 1.28;
      }
    }

    &_subtitle {
      font-size: 25px;
      line-height: 1.4;
      margin-top: 6px;
      text-align: center;
      @media only screen and (max-width: $xs) {
        font-size: 16px;
        line-height: 1.38;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;

    &_co-authors {
      width: 55%;
      font-size: 16px;
      line-height: 1.38;
      margin-top: 64px;
      @media only screen and (max-width: $md) {
        width: 100%;
      }
      @media only screen and (max-width: $xs) {
        font-size: 14px;
      }
    }

    &_main-image {
      margin-top: 30px;
    }

    .details {
      &__container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 74px;
      }

      &__properties {
        max-width: 30%;
        @media only screen and (max-width: $md) {
          width: 100%;
          max-width: 100%;
        }
        &_title {
          font-size: 43px;
          line-height: 1.21;
          font-family: $fontBodoni;
          @media only screen and (max-width: $xs) {
            font-size: 30px;
            line-height: 1.33;
            text-align: center;
          }
        }
        &_box {
          margin-top: 38px;
          @media only screen and (max-width: $md) {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
          }
          @media only screen and (max-width: $sm) {
            grid-template-columns: repeat(2, 1fr);
          }

          .item {
            display: flex;
            margin-top: 12px;
            @media only screen and (max-width: $md) {
              flex-direction: column;
            }
            @media only screen and (max-width: $xs) {
              align-items: center;
              margin-top: 18px;
            }
            &_title {
              width: 140px;
              font-size: 18px;
              line-height: 2.44;
              font-weight: 500;
              @media only screen and (max-width: $xs) {
                width: max-content;
              }
            }
            &_value {
              font-size: 16px;
              line-height: 2.75;
            }
          }
        }
      }
      &__description {
        max-width: 68%;
        font-size: 21px;
        line-height: 1.66;
        text-align: justify;

        @media only screen and (max-width: $md) {
          max-width: 100%;
          margin: 30px 0px;
        }
        @media only screen and (max-width: $xs) {
          margin: 50px 0px;
          font-size: 18px;
          line-height: 1.89;
        }
      }
      &__buttons-box {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @media only screen and (max-width: $xxl) {
          margin-top: 30px;
        }
        @media only screen and (max-width: $sm) {
          flex-direction: column;
          margin-top: 0;
        }

        .button {
          &--outline-white {
            margin-left: 12px;
            @media only screen and (max-width: $sm) {
              margin: 35px 0px;
            }
          }
          &--share {
            width: 140px;
            margin-left: 24px;
            @media only screen and (max-width: $sm) {
              width: max-content;
              margin-left: 0px;
              margin-bottom: 24px;
            }
          }
        }
      }
    }

    .creators {
      &__container {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        width: 100%;
      }

      &__title {
        font-size: 43px;
        line-height: 1.21;
        font-family: $fontBodoni;
        @media only screen and (max-width: $xs) {
          font-size: 30px;
          line-height: 1.33;
          text-align: center;
        }
      }

      &__group {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 196rem;
        margin-top: 38px;
        @media only screen and (max-width: $xxl) {
          height: 255rem;
        }
        @media only screen and (max-width: $xl) {
          height: 265rem;
        }
        @media only screen and (max-width: $md) {
          height: 315rem;
        }
        @media only screen and (max-width: $sm) {
          align-items: center;
          height: max-content;
          width: 100%;
          margin-top: 4.6rem;
          margin-left: 0%;
        }

        &_item {
          width: 29%;
          font-size: 21px;
          line-height: 1.86;
          @media only screen and (max-width: $xxl) {
            width: 28%;
          }
          @media only screen and (max-width: $sm) {
            font-size: 18px;
            line-height: 1.89;
            width: max-content;
          }

          &.clickable:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }

    // .reviews {
    //   &__container {
    //     display: flex;
    //     flex-direction: column;
    //     margin-top: 74px;
    //   }

    //   &__title {
    //     font-size: 43px;
    //     line-height: 1.21;
    //     font-family: $fontBodoni;
    //     margin-bottom: 5px;
    //     @media only screen and (max-width: $xs) {
    //       font-size: 30px;
    //       line-height: 1.33;
    //       text-align: center;
    //     }
    //   }

    //   &__item {
    //     display: flex;
    //     flex-direction: column;
    //     padding: 35px 0px;
    //     border-bottom: 1px solid $white;

    //     &:last-of-type {
    //       border-bottom: none;
    //     }

    //     &_text {
    //       font-style: italic;
    //       font-size: 21px;
    //       line-height: 1.66;
    //       @media only screen and (max-width: $xs) {
    //         font-size: 18px;
    //         line-height: 1.8;
    //       }
    //     }

    //     &_author {
    //       font-size: 18px;
    //       line-height: 2.44;
    //       margin-top: 12px;
    //       @media only screen and (max-width: $xs) {
    //         font-size: 16px;
    //         line-height: 2.75;
    //       }
    //     }
    //   }

    //   &__button {
    //     font-family: $fontBodoni;
    //   }
    // }
  }

  &__pop-up {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: $greyDark;
    opacity: 0;
    transform: scale(0);
    pointer-events: none;
    transition: all 0.5s;
    z-index: 999;

    &.visible {
      opacity: 1;
      pointer-events: all;
      transform: scale(1);
      transition: all 0.5s;
    }

    .button--oval {
      font-size: 1.7rem;
      color: $white;
      background-color: $greyDark;
      margin-top: 0rem;
      margin-right: 1rem;

      &:hover {
        background-color: $greyDark;
      }
    }

    &_header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 10rem;
      width: 100%;
    }

    .pdf-viewer {
      height: 85%;
      width: 100%;
    }
  }
}
</style>
