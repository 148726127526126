<template>
  <svg :width="width" :height="height" :viewBox="viewBox">
    <g>
      <path
        :fill="color"
        fill-rule="evenodd"
        d="M15.5052239 9.81240209c-.30105782-.3160949-.6348717-.59629112-.99523782-.83546027-.9544913-.63793981-2.06412712-.97693113-3.1975359-.97693113-1.57313387-.0030563-3.08234381.64933621-4.19277373 1.8123914l-5.38707898 5.62718337C.62405351 16.59885911.00099383 18.16954306.00000123 19.80741402c-.00208448 3.4178862 2.6507581 6.19037311 5.92536997 6.19254879 1.57070198.0056982 3.07852228-.64358621 4.18855515-1.80353328l4.44688123-4.64144594c.08079824-.08360819.12591225-.19767586.12526706-.3164057-.00134002-.24414214-.19206954-.44088557-.42597748-.43948692h-.16938845c-.92908054.00341892-1.84987281-.18280874-2.71046345-.5481154-.15856903-.06811943-.34081175-.02968246-.46161207.09723208l-3.1975359 3.34184107c-.9929052 1.03634785-2.60271592 1.03634785-3.59562112 0s-.9929052-2.71659272 0-3.75294057l5.40827115-5.64044465c.99211111-1.03422397 2.59929141-1.03422397 3.59140252 0 .66862036.65679568 1.71155297.65679568 2.3801237 0 .28765763-.30050254.46205875-.699066.49129104-1.12280514.03091972-.5063631-.14799777-1.00226223-.49134067-1.36145627z"
      />
      <path
        :fill="color"
        fill-rule="evenodd"
        d="M24.264223 1.81266408c-2.31439414-2.41688544-6.06677126-2.41688544-8.3811654 0l-4.44034103 4.63252349c-.1219277.1279488-.15709724.32039-.08889114.48625723.06741242.16633344.22495611.27309557.39787717.2696249h.1566012c.92750374-.00176125 1.8463267.18591426 2.70483065.55256267.15848618.0681185.34063366.02968205.46137086-.09723072l3.1873826-3.32413048c.99238633-1.03633346 2.6013558-1.03633346 3.59374212 0 .99238634 1.03633346.99238634 2.716555 0 3.75288845l-3.97048781 4.14186316-.03387983.03978327-1.38837854 1.4410557c-.99159266 1.03420962-2.5979331 1.03420962-3.58952576 0-.66827095-.65678655-1.71065855-.65678655-2.3788799 0-.28934267.30277763-.46380147.70480622-.4910343 1.13159575-.03095317.50635607.14787082 1.00224831.4910343 1.36148917.489943.51381543 1.06317186.9328348 1.69314819 1.23768446.08889115.04418636.1777823.07956654.26667344.1193498.08889115.03978328.18199868.07070855.27088983.10608873.08889114.03538017.18199867.06630544.27088982.09282762l.24975833.07070855c.16929994.04418637.33864948.07956654.5121658.11049182.20903308.0324275.4195543.05309616.63072039.06190235H14.71040875l.25397471-.03092528c.09310753-.0044031.19048103-.02652218.30055327-.02652218h.14390247l.29207092-.04418636.13546971-.02652218.24549235-.05304436h.04657857c1.03975856-.27273296 1.98918746-.83493066 2.74714323-1.62671097l5.3884802-5.62710523c2.31454296-2.41688544 2.31454296-6.33543392.00014882-8.75231936z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 26 26" },
    width: { type: String, default: "26" },
    height: { type: String, default: "26" },
    color: { type: String, default: "#fff" },
  },
};
</script>
