<template>
  <svg :width="width" :height="height" :viewBox="viewBox">
    <g>
      <path
        :fill="color"
        fill-rule="evenodd"
        d="M26.25 4.92176274C26.25 7.1223221 24.46624636 8.90625 22.265625 8.90625S18.28125 7.1223221 18.28125 4.92176274C18.28125 2.7214279 20.06500364.9375 22.265625.9375S26.25 2.7214279 26.25 4.92176274z"
      />
      <path
        :fill="color"
        fill-rule="evenodd"
        d="M17.34375 4.92175293c0 2.71447754 2.20733643 4.92199707 4.921875 4.92199707S27.1875 7.63623047 27.1875 4.92175293C27.1875 2.20751953 24.98016357 0 22.265625 0S17.34375 2.20751953 17.34375 4.92175293zm1.84570313.00003052c0-1.69540406 1.3793335-3.07608033 3.07617187-3.07608033 1.69677734 0 3.07611084 1.38067627 3.07611084 3.07608033 0 1.69561767-1.3793335 3.07629394-3.07611084 3.07629394-1.69683838 0-3.07617188-1.38067627-3.07617188-3.07629394z"
      />
      <path
        :fill="color"
        fill-rule="evenodd"
        d="M26.25 25.07823726c0 2.20033484-1.78375364 3.98426274-3.984375 3.98426274s-3.984375-1.7839279-3.984375-3.98426274c0-2.20055936 1.78375364-3.98448726 3.984375-3.98448726S26.25 22.8776779 26.25 25.07823726z"
      />
      <path
        :fill="color"
        fill-rule="evenodd"
        d="M17.34375 25.07824707C17.34375 27.79248047 19.55108643 30 22.265625 30s4.921875-2.20751953 4.921875-4.92175293c0-2.71447754-2.20733643-4.92199707-4.921875-4.92199707s-4.921875 2.20751953-4.921875 4.92199707zm1.84570313 0c0-1.69561768 1.3793335-3.07629394 3.07617187-3.07629394 1.69677734 0 3.07611084 1.38067626 3.07611084 3.07629394 0 1.69540405-1.3793335 3.07608032-3.07611084 3.07608032-1.69683838 0-3.07617188-1.38067627-3.07617188-3.07608032z"
      />
      <path
        :fill="color"
        fill-rule="evenodd"
        d="M8.90625 15.234375c0 2.20062136-1.7839279 3.984375-3.98448726 3.984375C2.7214279 19.21875.9375 17.43499636.9375 15.234375S2.7214279 11.25 4.92176274 11.25C7.1223221 11.25 8.90625 13.03375364 8.90625 15.234375z"
      />
      <path
        :fill="color"
        fill-rule="evenodd"
        d="M0 15.234375c0 2.71450806 2.20751953 4.921875 4.92175293 4.921875 2.71447754 0 4.92199707-2.20736694 4.92199707-4.921875S7.63623047 10.3125 4.92175293 10.3125C2.20751953 10.3125 0 12.51986694 0 15.234375zm1.84570313 0c0-1.69567871 1.3793335-3.07614136 3.0760498-3.07614136 1.69702148 0 3.07629394 1.38046265 3.07629394 3.07614136s-1.37927246 3.07614136-3.07629394 3.07614136c-1.6967163 0-3.0760498-1.38046265-3.0760498-3.07614136z"
      />
      <path
        :fill="color"
        fill-rule="evenodd"
        d="M7.81054617 14.53125c-.4339914 0-.85542654-.2328443-1.08486438-.64932727-.34038991-.6158956-.13081381-1.40153918.46640951-1.75398401l11.56891887-6.80169115c.59722332-.35362198 1.35904838-.1374935 1.70080804.48075646.34038991.6158956.13081381 1.40153918-.46640951 1.75398401L8.42626149 14.36267919c-.19450852.11442094-.40636755.16857081-.61571532.16857081z"
      />
      <path
        :fill="color"
        fill-rule="evenodd"
        d="M19.3771152 24.84375c-.20957776 0-.42143852-.05415133-.61594863-.16857536L7.19192483 17.87330015c-.5972282-.35104172-.80657767-1.13670647-.46618504-1.75403128.33902294-.6170894 1.10199563-.83440094 1.70059366-.48076942l11.56924173 6.80187449c.5972282.35104172.80657768 1.13670647.46618499 1.75403128-.2305812.4164942-.65201978.64934478-1.08464497.64934478z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 27 30" },
    width: { type: String, default: "27" },
    height: { type: String, default: "30" },
    color: { type: String, default: "#fff" },
  },
};
</script>
