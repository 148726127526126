<template>
  <div class="share__wrapper" tabindex="0" @focus="isBarOpen = true">
    <IconShare
      class="share__icon"
      :class="{ active: isBarOpen }"
      @click.native="isBarOpen = true"
    />

    <div class="share__icons-box" :class="{ active: isBarOpen }">
      <a class="share__icons-box_link">
        <IconLink
          class="share__icons-box_link_icon"
          tabindex="0"
          @keyup.enter.native="copyToClipboard"
          @click.native="copyToClipboard"
        />
      </a>

      <a
        class="share__icons-box_link"
        target="_blank"
        href="https://www.instagram.com/grottahouse/"
      >
        <IconInstagram class="share__icons-box_link_icon" />
      </a>

      <ShareNetwork
        class="share__icons-box_link"
        network="facebook"
        :url="
          currentDomain +
          '/api/public/share?url=' +
          url +
          '&title=' +
          title +
          '&description=' +
          description +
          '&image=' +
          image
        "
        :title="title"
        :description="description"
      >
        <IconFacebook class="share__icons-box_link_icon" />
      </ShareNetwork>
    </div>
  </div>
</template>

<script>
import IconShare from "@/components/icons/IconShare.vue";
import IconLink from "@/components/icons/IconLink.vue";
import IconInstagram from "@/components/icons/IconInstagram.vue";
import IconFacebook from "@/components/icons/IconFacebook.vue";

export default {
  props: {
    title: { type: String, default: "" },
    description: { type: String, default: "" },
    image: { type: String, default: "" },
  },

  components: { IconShare, IconLink, IconInstagram, IconFacebook },

  data() {
    return {
      isBarOpen: false,
      url: `${window.location}`,
      currentDomain: `${process.env.VUE_APP_BACKEND_URL}`,
    };
  },

  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.url);
    },
  },
};
</script>

<style lang="scss" scoped>
.share {
  &__wrapper {
    width: auto;
    display: inline-block;
    @media (hover: hover) {
      &:hover .share__icon {
        display: none;
      }
      &:hover .share__icons-box {
        display: flex;
      }
    }
  }
  &__icon {
    display: block;
    width: 2.7rem;
    cursor: pointer;
    &.active {
      display: none;
    }
  }
  &__icons-box {
    display: none;
    height: 3rem;
    width: 140px;
    justify-content: space-between;
    &.active {
      display: flex;
    }

    &_link {
      &_icon {
        width: auto;
        height: auto;
        cursor: pointer;
      }
    }
  }
}
</style>
