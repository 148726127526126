var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"share__wrapper",attrs:{"tabindex":"0"},on:{"focus":function($event){_vm.isBarOpen = true}}},[_c('IconShare',{staticClass:"share__icon",class:{ active: _vm.isBarOpen },nativeOn:{"click":function($event){_vm.isBarOpen = true}}}),_c('div',{staticClass:"share__icons-box",class:{ active: _vm.isBarOpen }},[_c('a',{staticClass:"share__icons-box_link"},[_c('IconLink',{staticClass:"share__icons-box_link_icon",attrs:{"tabindex":"0"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.copyToClipboard.apply(null, arguments)},"click":function($event){return _vm.copyToClipboard.apply(null, arguments)}}})],1),_c('a',{staticClass:"share__icons-box_link",attrs:{"target":"_blank","href":"https://www.instagram.com/grottahouse/"}},[_c('IconInstagram',{staticClass:"share__icons-box_link_icon"})],1),_c('ShareNetwork',{staticClass:"share__icons-box_link",attrs:{"network":"facebook","url":_vm.currentDomain +
        '/api/public/share?url=' +
        _vm.url +
        '&title=' +
        _vm.title +
        '&description=' +
        _vm.description +
        '&image=' +
        _vm.image,"title":_vm.title,"description":_vm.description}},[_c('IconFacebook',{staticClass:"share__icons-box_link_icon"})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }